import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Base64ToImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base64Data: '', // Store base64 data here
      imageUrl: '',   // Store URL of the converted image
      error: '',      // Store error message if any
    };
  }

  handleInputChange = (event) => {
    const base64Data = event.target.value;
    this.setState({
      base64Data: base64Data,
      imageUrl: '',  // Clear imageUrl when input changes
      error: '',     // Clear error message when input changes
    });
  };

  convertToImage = () => {
    const { base64Data } = this.state;
    try {
      // Create new Image object
      const img = new Image();
      img.src = base64Data;
      img.onload = () => {
        // Set imageUrl in state after image loads successfully
        this.setState({ imageUrl: base64Data });
      };
      img.onerror = () => {
        this.setState({ error: 'Invalid Base64 image data' });
      };
    } catch (error) {
      // Handle image loading error
      this.setState({ error: error.message });
    }
  };

  clearBase64Data = () => {
    this.setState({
      base64Data: '',
      imageUrl: '',
      error: '',
    });
  };

  copyBase64DataToClipboard = () => {
    const { base64Data } = this.state;
    if (base64Data) {
      navigator.clipboard.writeText(base64Data)
        .then(() => alert('Base64 data copied to clipboard!'))
        .catch((error) => console.error('Error copying to clipboard:', error));
    }
  };

  render() {
    const { base64Data, imageUrl, error } = this.state;

    return (
      <div>
        <Helmet>
          <title>{"Utility's - Base64 to Image Converter"}</title>
          <meta name="description" content="Convert Base64-encoded image data to viewable image with Utility's Base64 to Image Converter." />
          <meta name="keywords" content="Utility's, Base64 to Image, Image converter, Converter" />
        </Helmet>
        <main className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="text-center py-3">
                <h1 className="h2">Base64 to Image Converter</h1>
                <p className="lead mb-1">Convert Base64-encoded image data to viewable image</p>
              </div>
              <div className="form-group">
                <label htmlFor="base64Input"><strong>Paste Base64 Image Data</strong></label>
                {base64Data && (
                    <><a href="#" onClick={this.copyBase64DataToClipboard} className="input-group-append ml-2">
                      Copy to Clipboard
                    </a>
                      <a href="#" onClick={this.clearBase64Data} className="input-group-append ml-2">
                     Clear
                    </a></>
                  )}
                <div className="input-group">
                  <textarea
                    id="base64Input"
                    className="form-control"
                    rows="5"
                    value={base64Data}
                    onChange={this.handleInputChange}
                    placeholder="Paste your Base64 image data here..."
                  ></textarea>
                </div>
              </div>
              <button className="btn btn-primary mb-4" onClick={this.convertToImage}>Convert to Image</button>
              {error && <p className="text-danger">{error}</p>}
              {imageUrl && (
                <div className="text-center">
                  <img src={imageUrl} alt="Converted Image" className="img-fluid" />
                </div>
              )}
              <div className="mt-4">
                <p>
                  This Base64 to Image Converter tool allows you to easily convert Base64-encoded image data into a viewable image format. Simply paste your Base64 data into the input field above and click the "Convert to Image" button. The converted image will be displayed below. This tool is helpful for developers working with Base64-encoded images in web applications and APIs.
                </p>
                <p>
                  Base64 encoding is commonly used for embedding images in HTML, sending image data over the network, or storing image data in databases. With this converter, you can quickly visualize and verify Base64-encoded images directly within your browser.
                </p>
                <p>
                  Explore the capabilities of our Base64 to Image Converter and streamline your image data handling tasks. Convert Base64 images effortlessly and leverage the power of this tool in your development workflow.
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Base64ToImage;
