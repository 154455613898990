import { call, put, takeLatest } from "redux-saga/effects";
import { GET_VERIFIED_REQUEST, getVerifiedRequestFailure, getVerifiedRequestSuccess } from "../actions/verifyToken.action";
import { BASE_API_URLS, BASE_METHODS } from "../../../Utils/enum";
import { createRequestOptions } from "../../../Utils/createOptions";
import { fetchData } from "../../../Utils/axiosApiCall";

export function* getVerifiedRequestSaga({}) {
    try {
        const userLogindata = JSON.parse(localStorage.getItem("user"));
        const apiUrl = BASE_API_URLS.VERIFIED_API_URL;
        const methodValue = BASE_METHODS.GET
        const headersValue = { 'Content-Type': 'application/json' , "x-access-token" : userLogindata.token }
        const reqOptions = createRequestOptions(apiUrl, methodValue, headersValue, "")
        const {data} = yield call(fetchData, reqOptions);
        
        if (data.success) {
            yield put(getVerifiedRequestSuccess(data))
        } else {
            yield put(getVerifiedRequestFailure(data))
        }

    } catch (error) {
        console.log(error)
    }
}

export default function* userVerifiedDataSagas() {
    yield takeLatest(GET_VERIFIED_REQUEST, getVerifiedRequestSaga)
}