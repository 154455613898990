import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class FileToBase64 extends Component {
    state = {
        base64Data: '',
        selectedFile: null,
        errorMessage: '',
        clipText: "Copy to Clipboard",
        clipTextCopied: ""
    };

    handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64Data = e.target.result;
                this.setState({ base64Data, selectedFile: file, errorMessage: '' });
            };
            reader.onerror = (error) => {
                console.error('File reading error:', error);
                this.setState({ base64Data: '', selectedFile: null, errorMessage: 'Error reading file. Please try again.' });
            };
            reader.readAsDataURL(file);
        }
    };

    copyBase64DataToClipboard = () => {
        const { base64Data } = this.state;
        if (base64Data) {
            navigator.clipboard.writeText(base64Data)
                .then(() => this.setState({clipTextCopied: "Copied"}))
                .catch((error) => console.error('Error copying to clipboard:', error));
        }
    };

    render() {
        const { base64Data, selectedFile, errorMessage ,clipText ,clipTextCopied } = this.state;

        return (
            <div>
                <Helmet>
                    <title>{"Utility's - File to Base64 Converter"}</title>
                    <meta name="description" content="Convert any file to Base64 encoding with Utility's File to Base64 Converter." />
                    <meta name="keywords" content="Utility's, File to Base64, Base64 converter, Converter" />
                </Helmet>
                <main className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="text-center py-3">
                                <h1 className="h2">File to Base64 Converter</h1>
                                <p className="lead mb-1">Convert any file to Base64 encoding</p>
                            </div>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="fileInput"><strong>Choose a File</strong></label>
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        id="fileInput"
                                        onChange={this.handleFileInputChange}
                                    />
                                </div>
                                {selectedFile && (
                                    <div className="mb-4">
                                        <strong>Selected File:</strong> {selectedFile.name} ({selectedFile.type})
                                    </div>
                                )}
                                {base64Data && (
                                    <div className="border p-3 mt-4">
                                        <h4>Base64 Representation:</h4>
                                        
                                       {clipTextCopied? <p>{clipTextCopied}</p> :<a href="#" onClick={this.copyBase64DataToClipboard} className="input-group-append ml-2">
                                            {clipText}
                                        </a>}
                                        <br></br>
                                        <textarea
                                            rows="10"
                                            className="form-control"
                                            readOnly
                                            value={base64Data}
                                        />
                                        <small className="text-muted">Length: {base64Data.length} characters</small>


                                    </div>
                                )}
                                {errorMessage && (
                                    <div className="alert alert-danger mt-4" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                {!selectedFile && (
                                    <p className="text-muted">Select a file to view its Base64 representation.</p>
                                )}
                            </form>
                            <p>
                                Welcome to our File to Base64 Converter tool! This utility allows you to convert any file into its Base64 encoding,
                                making it easier to work with file contents in web applications or APIs.
                            </p>
                            <p>
                                To use this tool, simply choose a file using the file input above. Once selected, the tool will display the selected
                                file's details along with its Base64 representation. The Base64 data can then be used for various purposes such as
                                embedding images or sending binary data over the web.
                            </p>
                            <p>
                                File to Base64 conversion is a common requirement in web development, and our converter simplifies the process
                                by providing real-time conversion results right in your browser. Start using this tool to enhance your file
                                handling capabilities with Base64 encoding.
                            </p>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default FileToBase64;
