import { all } from "redux-saga/effects";
import userProfileModuleSaga from "../modules/UserProfile/redux/sagas";
import userLoginModuleSaga from "../modules/Authentication/Login/redux/sagas";
import userRegisterDataSaga from "../modules/Authentication/Registration/redux/sagas";
import headerModuleSaga from "../modules/Header/redux/sagas";
import userItemModuleSaga from "../modules/RestroBilling/redux/sagas";

export function* rootSaga() {
  yield all([
    userProfileModuleSaga(),
    userLoginModuleSaga(),
    userRegisterDataSaga(),
    headerModuleSaga(),
    userItemModuleSaga()
  ]);
}