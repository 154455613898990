import React, { Component } from 'react';

class Footer extends React.Component {
  render() {
    return <>
       <br></br>
            <br></br> <br></br>
            <br></br>
        <footer className="py-5 int-footer-background-color">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 mb-5">
              <h5>About Us</h5>
              <p>At Utilitys, we're committed to crafting tools that echo your individuality and aspirations. We understand that the tools you utilize are more than mere utilities; they're reflections of your style and objectives. Our comprehensive range of solutions is tailored to meet your diverse needs, ensuring that you can accomplish tasks effortlessly while staying true to your identity. With Utilitys, your tools become extensions of your personality, empowering you to navigate tasks with confidence and efficiency.</p>
              {/* <p>At Interior.in, we are passionate about creating spaces that reflect your unique style and personality. We understand that your home or workspace is not just a place; it's an extension of who you are and what you stand for. That's why we offer comprehensive interior solutions that cater to all your design and decor needs.</p> */}
            </div>

           

            <div className="col-12 col-lg-6 mb-3">
              <form>

                <a href="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
                  <img src="asset/logo/utilitys-logo.png" className='logo' alt="Logo" ></img>
                </a>
                <br></br>
                <h5 className='int-home-small-text-subscribe-footer'>Subscribe now to get the latest updates!</h5>
                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                  <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                  <input id="newsletter1" type="text" className="form-control" placeholder="Email address" fdprocessedid="smne5j" />
                  <button className="btn btn-primary" type="button" fdprocessedid="ja7ho">Subscribe</button>
                </div>

              </form>
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
            <p>© 2023 Company, Inc. All rights reserved.</p>
            <ul className="list-unstyled d-flex">
              {/* <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"><use xlink:href="#twitter"></use></svg></a></li>
              <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"><use xlink:href="#instagram"></use></svg></a></li>
              <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"><use xlink:href="#facebook"></use></svg></a></li> */}
            </ul>
          </div>
          </div>
        </footer>
     
    </>
  }
}

export default Footer;