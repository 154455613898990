export const GET_ADDRESS_DATA_REQUEST ='GET_ADDRESS_DATA_REQUEST';
export const GET_ADDRESS_DATA_REQUEST_SUCCESS ='GET_ADDRESS_DATA_REQUEST_SUCCESS';
export const GET_ADDRESS_DATA_REQUEST_FAILURE ='GET_ADDRESS_DATA_REQUEST_FAILURE';

export const getAddressDataRequest = (payload) => ({
    type: GET_ADDRESS_DATA_REQUEST, payload
}); 

export const getAddressDataRequestSuccess = (payload) => ({
    type: GET_ADDRESS_DATA_REQUEST_SUCCESS, payload
}); 

export const getAddressDataRequestFailure = (error) => ({
    type: GET_ADDRESS_DATA_REQUEST_FAILURE , error
}); 