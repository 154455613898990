// src/graphql/queries.js

import { gql } from '@apollo/client';

export const getItemSchema = () => `
  query {
    getItems {
        id
        itemName
        itemSize
        itemPrice
        ingredients
        discount
        isFavorite
        category
        createdAt
        updatedAt
        toppingId
        baseId
        restaurantId
    }
  }
`;
