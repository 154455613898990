import React, { Component } from 'react';
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux'
import { reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormWrapper } from '../../../Form/GenericFields/GenericWrappers';
import GenericFields from '../../../Form/GenericFields/GenericFields';
import { validateEmail, validateName, validatePasswordMatch } from '../../../Form/GenericFields/validation';
import { getRegisterRequest } from '../redux/actions/getRegisterData.action';

function mapStateToProps(state) {

    const { registerUserData, isLoading, errorMessage } = state.registerModuleReducer.GetRegisterReducer;
    const { values: registrationValues } = state.form.registerationForm || {};
    return { registerUserData, isLoading, errorMessage, registrationValues };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            registerRequest: getRegisterRequest,
        },
        dispatch
    );
}

const resetFormRegister = (result, dispatch) => dispatch(reset('registerationForm'));

class Registration extends Component {


    static propTypes = {
        registerRequest: PropTypes.func,
        registerUserData: PropTypes.array,
        isLoading: PropTypes.bool,
        errorMessage: PropTypes.string,
        registrationValues: PropTypes.array
    };

    state = {
        errorMessage: null
    }

    handleRegister = () => {
        const { registerRequest, registrationValues } = this.props;
        const { userEmail, userPassword, userFirstName, userLastName } = registrationValues;
        const payload = {
            email: userEmail, password: userPassword, firstName: userFirstName, lastName: userLastName,
        }
        registerRequest(payload);
    }

    componentDidUpdate = (prevProps) => {
        const { errorMessage, registerUserData } = this.props;
        if (prevProps.errorMessage !== errorMessage) {
            this.setState({ errorMessage: errorMessage })
        }
        if (registerUserData?.success) {
            this.props.reset()
        }
    }

    render() {
        const { handleSubmit, registerUserData } = this.props;
        const { errorMessage } = this.state;
        return (
            <>
                <div>
                    <section className="">
                                <FormWrapper tittle={"Sign Up"}>
                                    {registerUserData?.success ? <div class="alert alert-success" role="alert">
                                        {registerUserData?.success?.message}
                                    </div> : ""}
                                    {errorMessage ? <div class="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div> : ""}
                                    <form onSubmit={handleSubmit(this.handleRegister)}>
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <GenericFields name="userFirstName" label="First name" className="form-control" validate={[validateName]} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="form-outline">
                                                    <GenericFields name="userLastName" label="Last name" className="form-control" validate={[validateName]} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-outline mb-4">
                                            <GenericFields name="userEmail" label="Email address" className="form-control" validate={[validateEmail]} />
                                        </div>
                                        <div className="form-outline mb-4">
                                            <GenericFields name="userPassword" label="Password" type="password" className="form-control" />
                                        </div>
                                        <div className="form-outline mb-4">
                                            <GenericFields name="userConfirmPassword" label="Confirm Password" type="password" className="form-control" validate={[validatePasswordMatch]} />
                                        </div>
                                        <div className="form-check d-flex justify-content-center mb-4">
                                            <button type='submit' className="btn btn-primary int-register-button-prim mb-4">
                                                Sign up
                                            </button>
                                        </div>
                                        <div className="text-center">
                                            Already have an account?<NavLink to={'/login'} >Sign in</NavLink ></div>
                                    </form>
                                </FormWrapper>
                          
                    </section>
                </div>
            </>
        );
    }
}

export default reduxForm({
    form: 'registerationForm',
    destroyOnUnmount: false,
})(connect(
    mapStateToProps, mapDispatchToProps
)(Registration));