export const GET_REGISTER_REQUEST ='GET_REGISTER_REQUEST';
export const GET_REGISTER_REQUEST_SUCCESS ='GET_REGISTER_REQUEST_SUCCESS';
export const GET_REGISTER_REQUEST_FAILURE ='GET_REGISTER_REQUEST_FAILURE';

export const getRegisterRequest = payload => ({
    type: GET_REGISTER_REQUEST, payload
}); 

export const getRegisterRequestSuccess = (payload) => ({
    type: GET_REGISTER_REQUEST_SUCCESS, payload
}); 

export const getRegisterRequestFailure = (error) => ({
    type: GET_REGISTER_REQUEST_FAILURE , error
}); 