import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Base64ToPDFConverter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base64Data: '', // Store base64 data here
      pdfUrl: '',     // Store URL of the converted PDF
      errorMessage: ""
    };
  }

  handleInputChange = (event) => {
    const base64Data = event.target.value;
    this.setState({
      base64Data: base64Data,
    });
  };

  convertToPDF = () => {
    const { base64Data } = this.state;
    try {
      const base64Content = base64Data.replace(/^data:application\/pdf;base64,/, '');

      const byteString = atob(base64Content);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ab], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);

      this.setState({ pdfUrl, errorMessage: '' });
    } catch (error) {
      console.error('Base64 decoding error:', error);
      this.setState({ pdfUrl: '', errorMessage: 'Error converting to PDF. Please provide valid base64-encoded data.' });
    }
   
  };

  render() {
    const { pdfUrl,errorMessage } = this.state;

    return (
      <div className="container mt-5">
        <Helmet>
          <title>Base64 to PDF Converter - Utility's</title>
          <meta name="description" content="Convert Base64 to human-readable PDF with ease using Utility's Base64 to PDF Converter." />
          <meta name="keywords" content="Base64, PDF converter, Converter" />
        </Helmet>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="text-center py-3">
                <h1 className="h2">Base64 to PDF Converter</h1>
                <p className="lead mb-1">Convert Base64-encoded PDF data to viewable PDF</p>
              </div>
            <textarea
              className="form-control mb-4"
              rows="6"
              placeholder="Paste your base64 encoded data here..."
              value={this.state.base64Data}
              onChange={this.handleInputChange}
            />
            <button
              className="btn btn-primary mb-4"
              type="button"
              onClick={this.convertToPDF}
            >
              Convert to PDF
            </button>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <h3 className="mb-3">PDF Preview:</h3>
            {!pdfUrl && <p className="text-muted">The result of Base64 decoding will appear here</p>}
            {pdfUrl && (
              <div className="embed-responsive embed-responsive-4by3">
                <iframe
                  title="PDF Preview"
                  className="embed-responsive-item"
                  src={pdfUrl}
                  style={{ border: '1px solid #ddd' }}
                ></iframe>
              </div>
            )}
            <br></br>

            <p>
          Welcome to our Base64 to PDF Converter tool! This utility simplifies the process of converting Base64-encoded data into a downloadable PDF file. Whether you have Base64 data from an image, document, or any other file type, this converter allows you to decode the Base64 string and generate a PDF document instantly.
        </p>
        <p>
          To use this tool, simply paste your Base64 data into the input field, click the "Convert to PDF" button, and voila! The resulting PDF file will be displayed for download, ready for use in your projects. This tool is particularly useful for developers and designers working with file conversions, enabling seamless integration of Base64-encoded content into PDF format.
        </p>
        <p>
          Enhance your workflow with our Base64 to PDF Converter and unlock new possibilities for handling encoded data efficiently. Whether you're creating reports, exporting content, or managing assets, this converter streamlines the conversion process and improves productivity. Start using our tool today to experience the benefits of easy Base64 to PDF conversion.
        </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Base64ToPDFConverter;
