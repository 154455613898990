import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getUserDataRequest } from "../redux/actions/getUserData.action";
import { MY_ACCOUNT_CARDS ,MY_ACCOUNT_HEADING } from "../../Utils/enum";
import { NavLink, Navigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserDataRequest: getUserDataRequest,
        },
        dispatch
    );
    
};

const mapStateToProps = state => {
    const { isLoading, userData,  errorUserMessage } = state.userProfileModuleReducer.GetUserDataReducer;
    const { loginUserData } = state.loginModuleReducer.GetLoginReducer;
    return { isLoading, userData , loginUserData ,errorUserMessage}
};

export default connect(mapStateToProps, mapDispatchToProps)(class UserProfile extends React.Component {

    static propTypes = {
        getUserDataRequest: PropTypes.func,
        isLoading: PropTypes.bool,
        userData: PropTypes.oneOfType([
            PropTypes.object, // Object type
            PropTypes.array,  // Array type
          ]),
        loginUserData: PropTypes.oneOfType([
            PropTypes.object, // Object type
            PropTypes.array,  // Array type
          ]),
        errorUserMessage: PropTypes.string
    }

    state = {
        isRedirect: false
    }

    componentDidMount = () => {
        const {loginUserData , getUserDataRequest} = this.props;
        const payload = {
            email: loginUserData.emailId
        }
        getUserDataRequest(payload);
    }

    componentDidUpdate = (prevProps) =>{
        const {errorUserMessage} = this.props;
        if (errorUserMessage && prevProps.errorUserMessage !== errorUserMessage) {
            this.setState({isRedirect : true});
        }
    }

    handleEditSave = () => {
        const { isEdit } = this.state;
        this.setState({ isEdit: !isEdit });
    }

    render() {
        const { isRedirect } = this.state;
        const { isLoading, userData , errorUserMessage} = this.props;
        return <>
            <div className="container px-4">
                <div class="main-body">
                    {isRedirect && <Navigate to={"/login?errorMessage=" + errorUserMessage} replace={false} />}
                    <h1>{MY_ACCOUNT_HEADING}</h1>
                    <br></br>
                    <div class="row gy-4 ">
                        {MY_ACCOUNT_CARDS && MY_ACCOUNT_CARDS.filter(item => item.CARD_VISIBLE).map((item,index) => {
                            return <>
                          
                                <div key={index} class="col-lg-4 d-flex align-items-stretch ">

                                    <div className="card int-profile-card-box">
                                        <div className="card-body">
                                        <NavLink to={item.ROUTE} className="int-profile-link-style">
                                            <div className="row">
                                                <div className="col-md-4 int-profile-card-image-display">
                                                    <img className="int-profile-card-image" src={item.IMAGE_SRC} alt={item.IMAGE_ALT} />
                                                </div>
                                                <div className="col-md-8 ">
                                                    <h5>{item.CARD_HEADING}</h5>
                                                    <p>{item.CARD_DISCRIPTION}</p>
                                                </div>
                                            </div>
                                             </NavLink>
                                        </div>
                                    </div>
                                </div>
                               
                            </>
                        })}
                    </div>
                </div>
            </div>

        </>
    }
});

// const mapStateToProps = (state) => {
//     return {
//       // Define the props that will be mapped from the state
//       someData: state.someReducer.someData,
//       anotherData: state.anotherReducer.anotherData,
//       // Add more props as needed, based on your state structure
//     };
//   };
