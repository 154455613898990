export const GET_LOGIN_REQUEST ='GET_LOGIN_REQUEST';
export const GET_LOGIN_REQUEST_SUCCESS ='GET_LOGIN_REQUEST_SUCCESS';
export const GET_LOGIN_REQUEST_FAILURE ='GET_LOGIN_REQUEST_FAILURE';

export const getLoginRequest = payload => ({
    
    type: GET_LOGIN_REQUEST, payload
}); 

export const getLoginRequestSuccess = (payload) => ({
    type: GET_LOGIN_REQUEST_SUCCESS, payload
}); 

export const getLoginRequestFailure = (error) => ({
    type: GET_LOGIN_REQUEST_FAILURE , error
}); 