import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

function mapStateToProps(state) {

   }

const mapDispatchToProps = {
 
};


class QuickBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: props.products.map(product => ({
        ...product,
        quantity: 1, // Default quantity
      })),
      printView: false
    };
  }

// static propTypes = {
//     products: PropTypes.shape([])
// };

// state = {
//     products: this.props.products.map(product => ({
//                 ...product,
//                 quantity: 1, // Default quantity
//               })),
// }


  handleIncrement = index => {
    this.setState(prevState => {
      const updatedProducts = [...prevState.products];
      updatedProducts[index].quantity++;
      return { products: updatedProducts };
    });
  };

  componentDidUpdate =(prevProps)=>{
    if (prevProps.products !== this.props.products) {
        this.setState({
            products: this.props.products.map(product => ({
                ...product,
                quantity: 1, // Default quantity
              })),
        })       
    }
  }

  handleDecrement = index => {
    this.setState(prevState => {
      const updatedProducts = [...prevState.products];
      if (updatedProducts[index].quantity > 1) {
        updatedProducts[index].quantity--;
      }
      return { products: updatedProducts };
    });
  };

  calculateTotal = () => {
    const { products } = this.state;
    let total = 0;
    products.forEach(product => {
      total += product.price * product.quantity;
    });
    return total.toFixed(2);
  };

  handlePrintBill = () => {
    // const printContents = document.getElementById('print-bill');
    // if (printContents) {
    //   printJS({ printable: printContents.innerHTML, type: 'html' });
    // }else{
    //   console.log(printContents);
    // }
    //ipcRenderer?.send('print-html', '<html><body>Hello from React!</body></html>');
   };

  render() {
    const { products,printView } = this.state;
    console.log(this.props);
 
    return (
      <div className="container">
        <h2>Billing</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((product, index) => (
              <tr key={index}>
                <td>{product.name}</td>
                <td>${product.price.toFixed(2)}</td>
                <td>
                  <button
                    className="btn btn-sm btn-primary mr-2"
                    onClick={() => this.handleDecrement(index)}
                  >
                    -
                  </button>
                  {product.quantity}
                  <button
                    className="btn btn-sm btn-primary ml-2"
                    onClick={() => this.handleIncrement(index)}
                  >
                    +
                  </button>
                </td>
                <td>${(product.price * product.quantity).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="total">
          <strong>Total: ${this.calculateTotal()}</strong>
        </div>
        <button className="btn btn-primary" id='printButton'>Print Bill</button>
       {<div id="print-bill" style={{display:"none"}}>
        <div id='Print-idmain' class="container-print">
        <div class="logo">
            <img src="restaurant_logo.png" alt="Restaurant Logo"/>
        </div>
        <div class="receipt-info">
            <p>Restaurant Name</p>
            <p>Address Line 1</p>
            <p>Address Line 2</p>
            <p>GST Number: GST123456789</p>
            <p>Date: 31/03/2024</p>
            <p>Token Number: 12345</p>
        </div>
        <div class="receipt-items">
 <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Item 1</td>
                        <td>2</td>
                        <td>$10.00</td>
                        <td>$20.00</td>
                    </tr>
                    <tr>
                        <td>Item 2</td>
                        <td>1</td>
                        <td>$15.00</td>
                        <td>$15.00</td>
                    </tr>
                </tbody>
            </table>        
		</div>
        <div class="total">
            <p>Subtotal: $32.50</p>
            <p>Discount: $1.25</p>
            <p>Total (after discount): $31.25</p>
        </div>
    </div>

        </div>}
      </div>
    );
  }
}

export default connect (
    null, null
)(QuickBilling);
