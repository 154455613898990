import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList
} from "react-icons/fa";
import { LinkContainer } from 'react-router-bootstrap';
import { NavLink } from 'react-router-dom';


const Sidebar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const menuItem = [
        {
            path: "/restrobilling",
            name: "Billing",
            icon: <FaTh />,
            active: true
        },
        {
            path: "/restrotable",
            name: "Tables",
            icon: <FaUserAlt />,
            active: false
        },
        {
            path: "/restrobilling",
            name: "Analytics",
            icon: <FaRegChartBar />,
            active: false
        },
        {
            path: "/comment",
            name: "Comment",
            icon: <FaCommentAlt />,
            active: false
        },
        {
            path: "/product",
            name: "Product",
            icon: <FaShoppingBag />,
            active: false
        },
        {
            path: "/productList",
            name: "Product List",
            icon: <FaThList />,
            active: false
        }
    ]
    return (
        <div className="container-fluid" style={{position:"absolute",top:"70px"}}>
            <div className="row flex-nowrap" style={{  overflow: "hidden"}}>
                <div className='col-auto' style={{paddingLeft:0,paddingRight:0}}>
                    <div >

                        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
                           
                                {menuItem.map((item, index) => 
                                    (
                                        <>
                                         <li className="nav-item">
                                         <LinkContainer to={item.path} key={index} class="nav-link active py-3 border-bottom rounded-0" aria-current="page" data-bs-toggle="tooltip" data-bs-placement="right" title={item.name} aria-label="Home" data-bs-original-title="Home">
      <Nav.Link> <div className="icon">{item.icon}</div></Nav.Link>
      </LinkContainer>
        </li>
                                        </>
                                    )
                                )}
                                {/* { menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassName={item.active ? "active": ''}>
                           <div className="icon">{item.icon}</div>
                           <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
                       </NavLink>
                   ))}  */}
                       

                        </ul>

                    </div>

                </div>
                <div className='col'>{children}</div>
            </div></div>
    );
};

export default Sidebar;