import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { FormWrapper, ProfileFormWrapper } from '../../Form/GenericFields/GenericWrappers';

const mapStateToProps = state => {
    const { isLoading, userData, errorUserMessage } = state.userProfileModuleReducer.GetUserDataReducer;
    return { isLoading, userData, errorUserMessage }
};

function mapDispatchToProps(dispatch) {
    return {

    };
}

class LoginUserDetails extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        userData: PropTypes.oneOfType([
            PropTypes.object, // Object type
            PropTypes.array,  // Array type
          ]),
        errorUserMessage: PropTypes.string
    }

    render() {
        const { isLoading, userData, errorUserMessage } = this.props;
        const { UserFirstName, UserLastName, UserEmail, UserPhone } = userData
        const childrens = [{tittle:"Login & Security" }]
        return (
            <div>
                <div>
                    <section className="">
                        <div className="px-4 py-5 px-md-5  text-lg-start" >
                            <div className="container">
                                <div className={"row gx-lg-5 align-items-center justify-content-center "}>
                                    <div className="col-lg-6 mb-5  mb-lg-0">
                                        <ProfileFormWrapper childrens={childrens} showNav={true} tittle={"Login & Security"} parrentTab={"Profile"} />
                                            <div className="card" style={{ marginTop: "3%" }}>
                                                <div className="card-body py-5 px-md-5">
                                                    <ul class="list-group list-group-flush">
                                                        <li class="list-group-item "><div className='row'>
                                                            <div className='col-md-8'>
                                                                <strong>Name</strong>
                                                                <p>{UserFirstName + " " + UserLastName}</p>
                                                            </div>
                                                            <div className='col-md-4 '>
                                                                <button type="button" class="btn btn-light">Edit</button>
                                                            </div>
                                                        </div>
                                                        </li>
                                                        <li class="list-group-item "><div className='row'>
                                                            <div className='col-md-8'>
                                                                <strong>E-mail:</strong>
                                                                <p>{UserEmail}</p>
                                                            </div>
                                                            <div className='col-md-4 '>
                                                                <button type="button" class="btn btn-light">Edit</button>
                                                            </div>
                                                        </div>
                                                        </li>
                                                        <li class="list-group-item "><div className='row'>
                                                            <div className='col-md-8'>
                                                                <strong>Primary mobile number:</strong>
                                                                <p>UserPhone</p>
                                                            </div>
                                                            <div className='col-md-4 '>
                                                                <button type="button" class="btn btn-light">Edit</button>
                                                            </div>
                                                        </div>
                                                        </li>
                                                        <li class="list-group-item "><div className='row'>
                                                            <div className='col-md-8'>
                                                                <strong>Password:</strong>
                                                                <p>*****</p>
                                                            </div>
                                                            <div className='col-md-4 '>
                                                                <button type="button" class="btn btn-light">Edit</button>
                                                            </div>
                                                        </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        );
    }
}

export default connect(
    mapStateToProps,
)(LoginUserDetails);