import React, { Component, useLayoutEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation  } from "react-router-dom";
import Header from './modules/Header/components/Header'
import Footer from './modules/Footer/components/Footer'
import Home from './modules/Home/components/Home';
import AboutUs from './modules/AboutUs/components/AboutUs';
import UserProfile from './modules/UserProfile/components/UserProfile'
import './less/main.css'
import Login from './modules/Authentication/Login/components/Login';
import Registration from './modules/Authentication/Registration/components/Registration';
import LoginUserDetails from './modules/UserProfile/components/LoginUserDetails';
import UserAddress from './modules/UserProfile/components/UserAddress';
import SideBar from './modules/SideBar/SideBar';
import Base64ToPDFConverter from './modules/Converters/components/Base64ToPDF';
import UnixConverter from './modules/Converters/components/UnixConverter';
// import TestGraphql from './modules/Converters/components/TestGraphql';
import ResHeader from './modules/Header/components/ResHeader';
import ResSidebar from './modules/SideBar/ResSidebar';
import RestroBilling from './modules/RestroBilling/components/RestroBilling';
import RestroTable from './modules/RestroSaga/components/RestroTable';
import FileToBase64 from './modules/Converters/components/FiletoBase64';
import Base64ToImage from './modules/Converters/components/Base64ToImage';
import DaysCalculator from './modules/Converters/components/DaysCalculator';
import AgeCalculator from './modules/Converters/components/AgeCalculator';
import NavigationCards from './modules/Converters/components/NavigationCards';

function App() {

    const Wrapper = ({children}) => {
        const location = useLocation();
        // const printButton = document.getElementById('printButton');

        // printButton.addEventListener('click', () => {
        //     const htmlContent = '<html><body>Hello from Electron renderer process!</body></html>';
        //     ipcRenderer.send('print-html', htmlContent);
        // });
        useLayoutEffect(() => {
          document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);
        return children
      } 

    return (
    
        <Router >
            <div className="App">
                <Wrapper>
                    {/* <ResHeader/>
                    <ResSidebar> */}
                <Header />
                {/* <SideBar/> */}
                <div style={{top:"42px",position: "relative"}}>
                <Routes>
                    
                    <Route exact path="/" element={<NavigationCards/>} />
                    <Route path="/aboutus" element={<AboutUs/>} />
                    <Route path="/home" element={<Home/>} />
                    <Route path="/register" element={<Registration/>} />
                    <Route path="/login" element={<Login/>} />
                    <Route path='/profile' element={<UserProfile/>} />
                    <Route path='/login&security' element={<LoginUserDetails/>}/>
                    <Route path='/address' element={<UserAddress/>}/>
                    <Route path='/base64topdf' element={<Base64ToPDFConverter/>}/>
                    <Route path='/unixconverter' element={<UnixConverter/>}/>
                    <Route path='/filetobase64' element={<FileToBase64/>}/>
                    <Route path='/base64toimage' element={<Base64ToImage/>}/>
                    <Route path='/dayscalculator' element={<DaysCalculator/>}/>
                    <Route path='/agecalculator' element={<AgeCalculator/>}/>
                   
                </Routes>
                </div>
                {/* </ResSidebar> */}
                <Footer />
                </Wrapper>
            </div>
        </Router>

    );
}

export default App;
