import { call, put, takeLatest } from "redux-saga/effects";
import { GET_REGISTER_REQUEST, getRegisterRequestFailure, getRegisterRequestSuccess } from "../actions/getRegisterData.action";
import { sagaHandle } from "../../../../Utils/sagaDataHandling";
import { BASE_API_URLS, BASE_METHODS } from "../../../../Utils/enum";
import { createRequestOptions } from "../../../../Utils/createOptions";
import { fetchData } from "../../../../Utils/axiosApiCall";

export function* getRegisterRequestSaga({ payload }) {
    try {
        const { email, password, firstName, lastName } = payload;
        const apiUrl = BASE_API_URLS.REGISTER_API_URL;
        const bodyContent = {
            "email": email,
            "password": password,
            "firstName": firstName,
            "lastName": lastName
        };
        const methodValue = BASE_METHODS.POST
        const headersValue = { 'Content-Type': 'application/json' }

        const reqOptions = createRequestOptions(apiUrl, methodValue, headersValue, bodyContent)

        const {data} = yield call(fetchData, reqOptions);
        if (data?.success) {
            yield put(getRegisterRequestSuccess(data))
        } else {
            yield put(getRegisterRequestFailure(data))
        }
    } catch (error) {
        yield put(getRegisterRequestFailure(error))
    }
}

export default function* userRegisterDataSagas() {
    yield takeLatest(GET_REGISTER_REQUEST, getRegisterRequestSaga)
}