import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'react-bootstrap';
import { FormWrapper, ProfileFormWrapper } from '../../Form/GenericFields/GenericWrappers';
import GenericFields from '../../Form/GenericFields/GenericFields';
import { validateName, validateIsRequired, validateIsOnlyNumber } from '../../Form/GenericFields/validation';
import { Country, State, City } from 'country-state-city';

const AddressForm = ({ handleSubmit, initialValues, isNew }) => {
    const countryData = Country.getAllCountries().map(country => ({
        value: country.name,
        label: country.name,
        countryCode: country.isoCode
    }))

    const getCountryCodeByName = (countryName) => {
        const selectedCountry = countryData.find(country => country.label === countryName);
        return selectedCountry ? selectedCountry.countryCode : '';
    };

    const [selectedCountryCode, setSelectedCountryCode] = useState(getCountryCodeByName(initialValues?.Country?.value));



    const stateData = State.getStatesOfCountry(selectedCountryCode).map(state => ({
        value: state.name,
        label: state.name,
        stateCode: state.isoCode
    }))

    const getStateCodeByName = (stateName) => {
        const selectedState = stateData.find(state => state.label === stateName);
        return selectedState ? selectedState.stateCode : '';
    };

    const [selectedStateCode, setSelectedStateCode] = useState(getStateCodeByName(initialValues?.State?.value));


    const citydata = City.getCitiesOfState(selectedCountryCode, selectedStateCode).map(city => ({
        value: city.name,
        label: city.name,
        cityCode: city.isoCode
    }))





    const childrens = [{ tittle: "Your Addresses", path: '/address' }, { tittle: "Edit your address", path: '' }]
    return (<div>
        <section className="">
            <FormWrapper showNav={true} navTittle={"Edit Your Address"} parrentTab={"Profile"} childrens={childrens}>
                <Form onSubmit={handleSubmit}>
                    <div className="form-outline mb-4">
                        <GenericFields isDisabled={false} onChange={e => setSelectedCountryCode(e.countryCode)} name="Country" label="Country" type='select' options={countryData} className="form-control" validate={[validateIsRequired]} />
                    </div>
                    <div className="form-outline mb-4">
                        <GenericFields name="AddressName" label="Full name (First and Last name)" className="form-control" validate={[validateName]} />
                    </div>
                    <div className="form-outline mb-4">
                        <GenericFields name="MobileNumber" label="Mobile number" className="form-control" validate={[validateIsRequired, validateIsOnlyNumber]} />
                    </div>
                    <div className="form-outline mb-4">
                        <GenericFields name="Pincode" label="Pincode" className="form-control" validate={[validateIsRequired, validateIsOnlyNumber]} />
                    </div>
                    <div className="form-outline mb-4">
                        <GenericFields name="HouseNumber" label="Flat, House no., Building, Company, Apartment" className="form-control" validate={[validateIsRequired]} />
                    </div>
                    <div className="form-outline mb-4">
                        <GenericFields name="StreetName" label="Area, Street, Sector, Village" className="form-control" validate={[validateIsRequired]} />
                    </div>
                    <div className="form-outline mb-4">
                        <GenericFields name="LandMark" label="Landmark" className="form-control" validate={[validateIsRequired]} />
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <div className="form-outline">
                                <GenericFields name="City" label="Town/City" className="form-control" type='select' options={citydata} validate={[validateIsRequired]} />
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="form-outline">
                                <GenericFields name="State" value={initialValues?.State} label="State" onChange={(e) => setSelectedStateCode(e.stateCode)} type='select' options={stateData} className="form-control" validate={[validateIsRequired]} />
                            </div>
                        </div>
                    </div>
                    <Button type="submit">Submit</Button>
                </Form>
            </FormWrapper>
        </section>
    </div>
    )
};

export default reduxForm({
    form: 'addressForm', // Provide a unique form name
})(AddressForm);
