import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NavLink, Navigate } from 'react-router-dom';
class NavigationCards extends Component {
  renderNavOption = (option) => {
    const { heading, urlPath, children } = option;

    if (children) {
      return (
        children.map((child) => (
          <div className="col-lg-4 d-flex align-items-stretch ">
            <div className="card int-profile-card-box">
              <div className="card-body">
                <NavLink to={child.path} className="int-profile-link-style">
                  <div className="row">
                    <div className="col-md-8 ">
                      <h5>{child.name}</h5>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        ))
      );
    } else if (urlPath) {
      return (
        <div className="col-lg-4 d-flex align-items-stretch ">
          <div className="card int-profile-card-box">
            <div className="card-body">
              <NavLink to={urlPath} className="int-profile-link-style">
                <div className="row">
                  <div className="col-md-8 ">
                    <h5>{heading}</h5>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      );
    } else {
      return null; // Render nothing if there's no valid content
    }
  };

  render() {
    const listOfNavOption = [
      {
        heading: 'Base 64',
        children: [
          {
            name: 'Base 64 to PDF',
            path: '/base64topdf'
          },
          {
            name: 'Base 64 to Image',
            path: '/base64toimage'
          },
          {
            name: 'Any file to Base 64',
            path: '/filetobase64'
          }
        ]
      },
      {
        heading: 'Date & Time',
        children: [
          {
            name: 'Unix Timestamp',
            path: '/unixconverter'
          },
          {
            name: 'Days Calculator',
            path: '/dayscalculator'
          },
          {
            name: 'Age Calculator',
            path: '/agecalculator'
          }
        ]
      },
      {
        heading: 'About Us',
        urlPath: '/aboutus'
      }
    ];

    return (
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h2 className="text-center mb-4">Navigation</h2>
            <div className="row g-2">
            {listOfNavOption.map((option) => this.renderNavOption(option))}
            </div>
            <br></br>
            <br></br>
            <div className="mt-5">
              <p>
                Utility Tools offers a range of convenient conversion utilities designed to enhance your productivity. Explore our tools today and simplify your data transformation tasks!
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavigationCards;
