import { createStore , applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // You can choose local or session storage
import "regenerator-runtime/runtime";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from './root.reducer';
import { rootSaga } from './root.saga';

//create saga middleware
const sagaMiddleware = createSagaMiddleware();

// Applya middleware
const middleware = applyMiddleware(sagaMiddleware);

const store = createStore(rootReducer, composeWithDevTools(middleware));

export default store;
// run the saga
sagaMiddleware.run(rootSaga);