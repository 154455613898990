import {
    SET_ADDRESS_DATA_REQUEST,
    SET_ADDRESS_DATA_REQUEST_SUCCESS, SET_ADDRESS_DATA_REQUEST_FAILURE
} from "../actions/setAddressData.action";


const initialState = {
    addressSetMessage: null,
    isLoading: false,
    errorSetAddressMessage:null
}

export const SetAddressDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADDRESS_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                addressSetMessage: null,
                errorSetAddressMessage: null
            }
            break;
        case SET_ADDRESS_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                addressSetMessage: action.payload.success.message,
                errorSetAddressMessage: null
            }
            break;
        case SET_ADDRESS_DATA_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                addressSetMessage: null,
                errorSetAddressMessage: action?.payload?.error || action?.error?.error
            }
            break;

        default:
            return {
                ...state
            }
            break;
    }
}