import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResSidebar from '../../SideBar/ResSidebar';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class ResHeader extends Component {
    render() {
        return (
            <div>
                <div className="container fixed-top">
                  
                    <header className="d-flex justify-content-center py-3 border-bottom">
                        <ul className="nav nav-pills">
                            <li className="nav-item"><a href="#" className="nav-link active" aria-current="page">Home</a></li>
                            <li className="nav-item"><a href="#" className="nav-link">Features</a></li>
                            <li className="nav-item"><a href="#" className="nav-link">Pricing</a></li>
                            <li className="nav-item"><a href="#" className="nav-link">FAQs</a></li>
                            <li className="nav-item"><a href="#" className="nav-link">About</a></li>
                        </ul>
                    </header>
                
                </div>
               
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(ResHeader);