import React, { Component } from 'react';

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false,
        };
        this.menuSliderRef = React.createRef();
    }
    componentDidMount() {
        // Attach a click event listener to the document
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        // Remove the click event listener when the component unmounts
        document.removeEventListener('click', this.handleClickOutside);
    }
    handleClickOutside = (event) => {
        event.stopPropagation();
        // Check if the click event target is outside the menu slider
        if (this.menuSliderRef.current && !this.menuSliderRef.current.contains(event.target) && event.target.className !== 'fa fa-times') {
            this.setState({ isCollapsed: false });
        }
    };
    toggleSidebar = () => {
        this.setState((prevState) => ({
            isCollapsed: !prevState.isCollapsed,
        }));
    };

    

    render() {
        const { isCollapsed } = this.state;
        const sidebarClass = isCollapsed ? 'sidebar-collapsed' : '';

        return (
            <>
                {/* <header class="d-flex flex-wrap  py-3 mb-4 border-bottom int-sidbar"> */}
                   <div ref={this.menuSliderRef} className={sidebarClass ? `${sidebarClass}` : 'sidebar'}>
                   <button onClick={this.toggleSidebar} className="toggle-button">
                        <i className={!isCollapsed ? 'fa fa-bars' : 'fa fa-times'}></i>
                    </button>
                    {isCollapsed && (
                        <div class="flex-shrink-0 p-3" style={{ width: "280px" }}>
                            <a href="/" class="d-flex align-items-center pb-3 mb-3 link-body-emphasis text-decoration-none border-bottom">
                                <span class="fs-5 fw-semibold">Collapsible</span>
                            </a>
                            <ul class="nav nav-pills flex-column mb-auto">
                                <li class="">
                                    <a href="/" class="nav-link link-body-emphasis" aria-current="page">
                                        <i class="fa fa-home" aria-hidden="true"> </i> &nbsp;
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="/templates" class="nav-link link-body-emphasis">
                                    <i class="fa fa-file-text" aria-hidden="true"></i> &nbsp;
                                        Templates
                                    </a>
                                </li>
                                <li>
                                    <a href="/bulkscan" class="nav-link link-body-emphasis">
                                    <i class="fa fa-qrcode" aria-hidden="true"></i> &nbsp;
                                        Bulk Scan
                                    </a>
                                </li>
                               
                            </ul>
                        </div>

                    )}
                </div></>
        );
    }
}

export default SideBar;