import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { ProfileFormWrapper } from '../../Form/GenericFields/GenericWrappers';
import { MessageBanner } from '../../Form/GenericFields/MessageBanner';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class DisplayAddress extends Component {

    static propTypes = {
        addressData: PropTypes.oneOfType([
            PropTypes.object, // Object type
            PropTypes.array,  // Array type
        ]),
        handelEdit: PropTypes.func,
        handelRemove: PropTypes.func,
        handelDefault: PropTypes.func,
        handleAddNew: PropTypes.func
    }

    render() {
        const { addressSetMessage, addressData, handelEdit, handelRemove, handelDefault ,handleAddNew } = this.props;
        const childrens = [{ tittle: "Your Addresses" }]
        return (
            <div>
                <div >
                    <section className="">
                        <div className="px-4 py-5 px-md-5  text-lg-start" >
                            <div className="container">

                                <ProfileFormWrapper showNav={true} tittle={"Your Addresses"} parrentTab={"Profile"} childrens={childrens} />
                                {addressSetMessage && <MessageBanner messageType={'success'} messageContent={"Set Successfully"} />}
                                <div className={"row gx-4 gy-4 align-items-center int-profile"}>

                                    <div className="col-lg-4 mb-5  mb-lg-0">
                                        <a onClick={() => handleAddNew()}>
                                            <div className="int-profile-first-new-address-box dashed">
                                                <div className='int-profile-first-plus-sign'></div>
                                                <h5 className="int-profile-first-box-text">Add address</h5>
                                            </div>
                                        </a>
                                    </div>
                                    {addressData && addressData.filter(e => e.DefaultAddress === 1).map((item, index) => {

                                        return <> <div key={item.AddressID} className="col-lg-4 mb-5  mb-lg-0">
                                            <div className="int-profile-default-address-box">
                                                <div className='section-head'><p>Default</p></div>
                                                <div className='section-main'>
                                                    <strong>{item.AddressName}</strong>
                                                    <p>{item.HouseNumber}<br></br>
                                                        {item.StreetName}  <br></br>
                                                        {item.LandMark}  <br></br>
                                                        {item.City + ", " + item.State + ", " + item.Pincode} <br></br>
                                                        {item.Country}  <br></br>
                                                        {"Phone number: " + item.MobileNumber}
                                                    </p>
                                                </div>
                                                <div className='bootom-edit-link'>
                                                    <a onClick={() => handelEdit(item)}>Edit</a>
                                                    <strong>&nbsp; | &nbsp;</strong> <a onClick={() => handelRemove(item.AddressID)}>Remove</a>
                                                </div>

                                            </div>
                                        </div></>
                                    })}
                                    {addressData && addressData.filter(e => e.DefaultAddress === 0).map((item, index) => {
                                        return <> <div key={item.AddressID} className="col-lg-4 mb-5  mb-lg-0">

                                            <div className="int-profile-default-address-box">
                                                <div className='section-main'>
                                                    <strong>{item.AddressName}</strong>
                                                    <p>{item.HouseNumber}<br></br>
                                                        {item.StreetName}  <br></br>
                                                        {item.LandMark}  <br></br>
                                                        {item.City + ", " + item.State + ", " + item.Pincode} <br></br>
                                                        {item.Country}  <br></br>
                                                        {"Phone number: " + item.MobileNumber}
                                                    </p>
                                                </div>
                                                <div className='bootom-edit-link'>
                                                    <a onClick={() => handelEdit(item)}>Edit</a>
                                                    <strong>&nbsp; | &nbsp;</strong> <a onClick={() => handelRemove(item.AddressID)}>Remove</a>
                                                    <strong>&nbsp; | &nbsp;</strong> <a onClick={() => handelDefault(item)}>Set as Default</a>
                                                </div>

                                            </div>
                                        </div></>
                                    })}

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(DisplayAddress);