import { call, put, takeLatest } from "redux-saga/effects";
import { GET_ITEM_REQUEST, getItemRequestFailure, getItemRequestSuccess } from "../actions/itemData.action";
import { getItemSchema } from "../schemas/item_data.schema";
import client from "../../../../graphql/client";
import { sagaHandle } from "../../../Utils/sagaDataHandling";
import { Query } from "../../../../api/graphql.config";

export function* getItemRequestSaga() {
    try {
         yield call(
           sagaHandle,
            Query,
            getItemSchema(),
           getItemRequestSuccess,
         getItemRequestFailure
        )
    } catch (error) {
        console.log(error)
    }
}

export default function* userItemDataSagas() {
    yield takeLatest(GET_ITEM_REQUEST, getItemRequestSaga)
}