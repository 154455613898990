import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Navigate } from 'react-router-dom';
import { getLoginRequest } from '../redux/actions/getLoginData.action';
import PropTypes from "prop-types";
import { reduxForm } from 'redux-form';
import GenericFields from '../../../Form/GenericFields/GenericFields';
import { FormWrapper } from '../../../Form/GenericFields/GenericWrappers';
import { validateEmail } from '../../../Form/GenericFields/validation.js';
import { changesRoute } from '../../../Utils/functionBasedForHooks';
import { getParamValue } from '../../../Utils/getUrlParam';

function mapStateToProps(state) {

    const { loginUserData, isLoading, errorMessage } = state.loginModuleReducer.GetLoginReducer;
    const { values: loginValues } = state.form.loginForm || {};

    return { loginUserData, isLoading, errorMessage, loginValues };
}

const mapDispatchToProps = {
    loginRequest: getLoginRequest
};

class Login extends Component {

    static propTypes = {
        loginRequest: PropTypes.func,
        loginUserData: PropTypes.oneOfType([
            PropTypes.object, // Object type
            PropTypes.array,  // Array type
          ]),
        isLoading: PropTypes.bool,
        errorMessage: PropTypes.string,
        loginValues: PropTypes.oneOfType([
            PropTypes.object, // Object type
            PropTypes.array,  // Array type
          ]),
    };
    state = {
        isRedirect: false
    }

    handleLogin = () => {
        const { loginRequest, loginValues } = this.props;
        const { userEmail, userPassword } = loginValues;
        const payload = {
            email: userEmail, password: userPassword
        }
        loginRequest(payload);

    }

    componentDidUpdate = (prevProps) => {
        const { loginUserData, history } = this.props;
        if (prevProps.loginUserData !== loginUserData) {
            if (loginUserData.success) {
                localStorage.setItem("user", JSON.stringify(loginUserData));
                this.setState({isRedirect : true});
            }
        }
    }



    render() {
        const { handleSubmit, errorMessage } = this.props;
        const { isRedirect } = this.state;
        return (
            <div>
                <section className="">
                    
                    <FormWrapper tittle={"Sign In"}>
                    { isRedirect && (<Navigate to="/profile" replace={true} render={true} /> )}
                    {errorMessage || getParamValue("errorMessage") ? <div class="alert alert-danger" role="alert">
                        {errorMessage || getParamValue("errorMessage")}
                    </div> : ""}
                    <form onSubmit={handleSubmit(this.handleLogin)}>
                        <div className="form-outline mb-4">
                            <GenericFields name="userEmail" label="Email address" className="form-control" validate={[validateEmail]} />
                            {/* <Field name="userEmail" component={this.renderInput} label="Email address" className="form-control" /> */}
                        </div>
                        <div className="form-outline mb-4">
                            <GenericFields name="userPassword" label="Password" type="password" className="form-control" />
                            {/* <Field  name="userPassword" component={this.renderInput} label="Password" type="password" className="form-control" /> */}
                        </div>
                        <div className="form-check d-flex justify-content-center mb-4">
                            <button type='submit' className="btn btn-primary int-register-button-prim mb-4">
                                Sign in
                            </button>
                        </div>
                        <div className="text-center"> New to Insterior?<NavLink to={'/register'} >Sign up</NavLink ></div>
                    </form>
                </FormWrapper></section>
            </div>
        );
    }
}


export default reduxForm({
    form: 'loginForm',
    destroyOnUnmount: false,
})(connect(
    mapStateToProps, mapDispatchToProps
)(Login));
