import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class AgeCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      birthDate: '',
      age: null,
      error: '',
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      age: null,
      error: '',
    });
  };

  calculateAge = () => {
    const { birthDate } = this.state;

    if (!birthDate) {
      this.setState({ error: 'Please enter your birthdate.' });
      return;
    }

    const today = new Date();
    const birthDateObj = new Date(birthDate);

    if (isNaN(birthDateObj.getTime())) {
      this.setState({ error: 'Invalid date format. Please use YYYY-MM-DD.' });
      return;
    }

    const ageInMilliseconds = today - birthDateObj;
    const ageDate = new Date(ageInMilliseconds);

    const years = ageDate.getUTCFullYear() - 1970;
    const months = ageDate.getUTCMonth();
    const days = ageDate.getUTCDate() - 1;
    const hours = ageDate.getUTCHours();
    const minutes = ageDate.getUTCMinutes();
    const seconds = ageDate.getUTCSeconds();

    const age = {
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
    };

    this.setState({ age });
  };

  render() {
    const { birthDate, age, error } = this.state;

    return (
      <div>
        <Helmet>
          <title>{"Utility's - Age Calculator"}</title>
          <meta name="description" content="Calculate your age based on birthdate with Utility's Age Calculator." />
          <meta name="keywords" content="Utility's, Age Calculator, Birthdate Calculator, Calculator" />
        </Helmet>
        <main className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="text-center py-3">
                <h1 className="h2">Age Calculator</h1>
                <p className="lead mb-1">Calculate your age based on birthdate</p>
              </div>
              <form>
                <div className="form-group">
                  <label htmlFor="birthDate"><strong>Birthdate</strong></label>
                  <input
                    type="date"
                    className="form-control"
                    id="birthDate"
                    name="birthDate"
                    value={birthDate}
                    onChange={this.handleInputChange}
                  />
                </div>
                <button className="btn btn-primary mb-4" type="button" onClick={this.calculateAge}>
                  Calculate Age
                </button>
                {error && <p className="text-danger">{error}</p>}
                {age && (
                  <div className="text-center">
                    <h2 className="mb-4">Your Age</h2>
                    <div className="age-details">
                      <p>
                        <strong>Years:</strong> {age.years}
                      </p>
                      <p>
                        <strong>Months:</strong> {age.months}
                      </p>
                      <p>
                        <strong>Days:</strong> {age.days}
                      </p>
                      <p>
                        <strong>Hours:</strong> {age.hours}
                      </p>
                      <p>
                        <strong>Minutes:</strong> {age.minutes}
                      </p>
                      <p>
                        <strong>Seconds:</strong> {age.seconds}
                      </p>
                    </div>
                  </div>
                )}
              </form>
              <div className="mt-5">
                <h3>How to Use:</h3>
                <p>
                  Enter your birthdate using the date input field above.
                </p>
                <p>
                  Click the "Calculate Age" button to compute your age based on the entered birthdate.
                </p>
                <p>
                  The calculated age will be displayed in years, months, days, hours, minutes, and seconds.
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default AgeCalculator;
