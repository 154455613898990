import { call, put, takeLatest } from "redux-saga/effects";
import { GET_ADDRESS_DATA_REQUEST, getAddressDataRequest, getAddressDataRequestFailure, getAddressDataRequestSuccess } from "../actions/getAddressData.action";
import { BASE_API_URLS, BASE_METHODS } from "../../../Utils/enum";
import { createRequestOptions } from "../../../Utils/createOptions";
import { fetchData } from "../../../Utils/axiosApiCall";
import { getAuthToken } from "../../../Utils/authUser";
import { SET_ADDRESS_DATA_REQUEST, setAddressDataRequestFailure, setAddressDataRequestSuccess } from "../actions/setAddressData.action";

export function* getAddressDataRequestSaga(payload) {
    try {
        const { email } = payload.payload;
        const userLogindata = JSON.parse(localStorage.getItem("user"));
        const apiUrl = BASE_API_URLS.ALL_ADDRESS_API_URL;
        const bodyContent = {
            "email": email ? email : userLogindata.emailId ,
        };
        const methodValue = BASE_METHODS.POST
       
        const headersValue = { 'Content-Type': 'application/json' , "x-access-token" : userLogindata.token }

        const reqOptions = createRequestOptions(apiUrl, methodValue, headersValue, bodyContent)

        const {data} = yield call(fetchData, reqOptions);
        if (data.success) {
            yield put(getAddressDataRequestSuccess(data));
        } else {
            yield put(getAddressDataRequestFailure(data));
        }
    } catch (error) {
        yield put(getAddressDataRequestFailure(error));
    }
}

export function* setAddressDataRequestSaga({payload}) {
    try {
        const { AddressID,AddressName,City,Country,DefaultAddress,HouseNumber,LandMark,MobileNumber,Pincode,State,StreetName,UserID } = payload;
        const userLogindata = JSON.parse(localStorage.getItem("user"));
        const apiUrl = BASE_API_URLS.ADD_ADDRESS_API_URL;
        const bodyContent = {
            "userEmailAddress": userLogindata.emailId ,
            "addressIdUpdate": AddressID ? AddressID : null,
            "addressNameINPUT": AddressName,
            "houseNumber": HouseNumber,
            "streetName": StreetName,
            "landmark": LandMark,
            "mobileNumber": MobileNumber,
            "city": City,
            "state": State,
            "country": Country,
            "pincode": Pincode,
            "defaultAddress" : DefaultAddress
        };
        const methodValue = BASE_METHODS.POST
       
        const headersValue = { 'Content-Type': 'application/json' , "x-access-token" : userLogindata.token }

        const reqOptions = createRequestOptions(apiUrl, methodValue, headersValue, bodyContent)
        const {data} = yield call(fetchData, reqOptions);
        if (data.success) {
            yield put(setAddressDataRequestSuccess(data));
            const payloadGetData = {
                email: userLogindata.emailId 
            }
            yield put(getAddressDataRequest(payloadGetData));
        } else {
            yield put(setAddressDataRequestFailure(data));
        }
    } catch (error) {
        yield put(setAddressDataRequestFailure(error));
    }
}

export default function* addressDataSagas() {
    yield takeLatest(GET_ADDRESS_DATA_REQUEST, getAddressDataRequestSaga);
    yield takeLatest(SET_ADDRESS_DATA_REQUEST, setAddressDataRequestSaga);
}