import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getItemRequest } from "../redux/actions/itemData.action";
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import QuickBilling from './QuickBilling';
function mapStateToProps(state) {

    const { itemData, isLoading, errorMessage } = state.itemModuleReducer.ItemReducer;

    return { itemData, isLoading, errorMessage };
}

const mapDispatchToProps = {
    getItemRequest
};

class TestGraphql extends Component {

    static propTypes = {
        getItemRequest: PropTypes.func,
        itemData: PropTypes.oneOfType([
            PropTypes.object, // Object type
            PropTypes.array,  // Array type
        ]),
        isLoading: PropTypes.bool,
        errorMessage: PropTypes.string,
    };

    state = {
        sidebar: [{
            id: 1,
            catName: "Indian",
            dishes: [
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 }
            ],
            active: true
        }, {
            id: 2,
            catName: "Pasta",
            dishes: [
                { id: 1, itemName: "Pasta Red", itemType: "Veg", itemPrice: 20 },
                { id: 2, itemName: "Pasta Green", itemType: "Veg", itemPrice: 20 },
                { id: 3, itemName: "Pasta White", itemType: "Veg", itemPrice: 20 },
                { id: 4, itemName: "Pasta", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 3,
            catName: "Chienise",
            dishes: [
                { id: 1, itemName: "Chienise1", itemType: "Veg", itemPrice: 20 },
                { id: 2, itemName: "Chienise2", itemType: "Veg", itemPrice: 20 },
                { id: 3, itemName: "Chienise3", itemType: "Veg", itemPrice: 20 },
                { id: 4, itemName: "Chienise4", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 4,
            catName: "Indian",
            dishes: [
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 5,
            catName: "Pasta",
            dishes: [
                { id: 1, itemName: "Pasta Red", itemType: "Veg", itemPrice: 20 },
                { id: 2, itemName: "Pasta Green", itemType: "Veg", itemPrice: 20 },
                { id: 3, itemName: "Pasta White", itemType: "Veg", itemPrice: 20 },
                { id: 4, itemName: "Pasta", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 6,
            catName: "Chienise",
            dishes: [
                { id: 1, itemName: "Chienise1", itemType: "Veg", itemPrice: 20 },
                { id: 2, itemName: "Chienise2", itemType: "Veg", itemPrice: 20 },
                { id: 3, itemName: "Chienise3", itemType: "Veg", itemPrice: 20 },
                { id: 4, itemName: "Chienise4", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 7,
            catName: "Indian",
            dishes: [
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 8,
            catName: "Pasta",
            dishes: [
                { id: 1, itemName: "Pasta Red", itemType: "Veg", itemPrice: 20 },
                { id: 2, itemName: "Pasta Green", itemType: "Veg", itemPrice: 20 },
                { id: 3, itemName: "Pasta White", itemType: "Veg", itemPrice: 20 },
                { id: 4, itemName: "Pasta", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 9,
            catName: "Chienise",
            dishes: [
                { id: 1, itemName: "Chienise1", itemType: "Veg", itemPrice: 20 },
                { id: 2, itemName: "Chienise2", itemType: "Veg", itemPrice: 20 },
                { id: 3, itemName: "Chienise3", itemType: "Veg", itemPrice: 20 },
                { id: 4, itemName: "Chienise4", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 10,
            catName: "Indian",
            dishes: [
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 11,
            catName: "Pasta",
            dishes: [
                { id: 1, itemName: "Pasta Red", itemType: "Veg", itemPrice: 20 },
                { id: 2, itemName: "Pasta Green", itemType: "Veg", itemPrice: 20 },
                { id: 3, itemName: "Pasta White", itemType: "Veg", itemPrice: 20 },
                { id: 4, itemName: "Pasta", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 12,
            catName: "Chienise",
            dishes: [
                { id: 1, itemName: "Chienise1", itemType: "Veg", itemPrice: 20 },
                { id: 2, itemName: "Chienise2", itemType: "Veg", itemPrice: 20 },
                { id: 3, itemName: "Chienise3", itemType: "Veg", itemPrice: 20 },
                { id: 4, itemName: "Chienise4", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 13,
            catName: "Indian",
            dishes: [
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 },
                { id: 1, itemName: "paneer lababdar", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 14,
            catName: "Pasta",
            dishes: [
                { id: 1, itemName: "Pasta Red", itemType: "Veg", itemPrice: 20 },
                { id: 2, itemName: "Pasta Green", itemType: "Veg", itemPrice: 20 },
                { id: 3, itemName: "Pasta White", itemType: "Veg", itemPrice: 20 },
                { id: 4, itemName: "Pasta", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }, {
            id: 15,
            catName: "Chienise",
            dishes: [
                { id: 1, itemName: "Chienise1", itemType: "Veg", itemPrice: 20 },
                { id: 2, itemName: "Chienise2", itemType: "Veg", itemPrice: 20 },
                { id: 3, itemName: "Chienise3", itemType: "Veg", itemPrice: 20 },
                { id: 4, itemName: "Chienise4", itemType: "Veg", itemPrice: 20 }
            ],
            active: false
        }],
        billigMode: [{
            id: 1,
            name: "Dine-in",
            active: true,
            currentShowing: true
        }, {
            id: 2,
            name: "Delivery",
            active: true,
            currentShowing: false
        }, {
            id: 3,
            name: "Quike Billing",
            active: true,
            currentShowing: false
        }],
        currentShowing: 1,
        currentTab: 1,
        selectedItems:[]
    }

    componentDidMount = () => {
        // this.props.getItemRequest();
    }
    toggleActive = (id) => {
        const updatedData = this.state.sidebar.map(item => {
            if (item.id === id) {
                return { ...item, active: true };
            } else {
                return { ...item, active: false };
            }
        });
        this.setState({ sidebar: updatedData, currentTab: id });
    }

    toggleBillingActive = (id) => {
        const updatedData = this.state.billigMode.filter(e => e.active === true).map(item => {
            if (item.id === id) {
                return { ...item, currentShowing: true };
            } else {
                return { ...item, currentShowing: false };
            }
        });
        this.setState({ billigMode: updatedData, currentShowing: id });
    }

    toTitleCase = (str) => {
        const titleCase = str
            .toLowerCase()
            .split(' ')
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');

        return titleCase;
    }


    handleAddItem= (item)=>{
        const {selectedItems}= this.state
        const currentItem= {
            name: item.itemName, price: item.itemPrice
        }
        this.setState({selectedItems : [...selectedItems,currentItem]});
    }
    render() {
        const { itemData } = this.props
        const { sidebar, currentTab, billigMode } = this.state
        return (
            <div>

                {/* {isRedirect && <Navigate to={"/login?errorMessage=" + errorUserMessage} replace={false} />} */}
                {/* <h1>{MY_ACCOUNT_HEADING}</h1> */}
                {/* <br></br> */}
                <div className="row">
                    <div className="col-1 res-converter-scroller" style={{ paddingLeft: 0, paddingRight: 0 }}>

                        <div className="list-group list-group-flush border-bottom scrollarea">
                            {sidebar && sidebar.map((item, index) => {
                                return <>
                                    <div onClick={() => this.toggleActive(item.id)} key={index} className={"list-group-item list-group-item-action py-3 lh-tight " + (item.active ? 'active' : '')} aria-current="true">
                                        <div className="d-flex w-100 align-items-center justify-content-between">
                                            <strong className="mb-1">{item.catName}</strong>
                                        </div>
                                    </div>
                                </>
                            })}

                        </div>

                    </div>
                    <div className="col col-lg-6 res-converter-scroller">
                        <div className='row g-2'>
                            {sidebar && sidebar?.filter((e) => e.id === currentTab)[0].dishes?.map((item, index) => {
                                return <>

                                    <div key={index} onClick={()=>this.handleAddItem(item)} className="col-lg-4 d-flex align-items-stretch ">

                                        <div className="card int-profile-card-box">
                                            <div className="card-body">
                                                <NavLink  className="int-profile-link-style">
                                                    <div className="row">
                                                        {/* <div className="col-md-4 int-profile-card-image-display">
                                                    <img className="int-profile-card-image" src={item.IMAGE_SRC} alt={item.IMAGE_ALT} />
                                                </div> */}
                                                        <div className="col-md-8 ">
                                                            <h5>{this.toTitleCase(item.itemName)}</h5>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            })}
                        </div>


                    </div>
                    <div className="col col-lg-5 res-converter-scroller">
                        <ul className="nav nav-tabs">
                            {billigMode.filter(e => e.active === true).map((item, index) => (
                                <li key={index} className="nav-item">
                                    <a onClick={() => this.toggleBillingActive(item.id)} className={"nav-link " + (item.currentShowing ? 'active' : '')} aria-current="page" href="#">{item.name}</a>
                                </li>
                            ))}
                             {/* <li class="nav-item">
    <a class="nav-link active" aria-current="page" href="#">Active</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Link</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Link</a>
  </li>
  <li class="nav-item">
    <a class="nav-link disabled" aria-disabled="true">Disabled</a>
  </li> */}
                        </ul>
                        <div>
                            <QuickBilling products={this.state.selectedItems}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(TestGraphql);