import {
    GET_ADDRESS_DATA_REQUEST,
    GET_ADDRESS_DATA_REQUEST_SUCCESS, GET_ADDRESS_DATA_REQUEST_FAILURE
} from "../actions/getAddressData.action";


const initialState = {
    addressData: [],
    isLoading: false,
    errorAddressMessage:null
}

export const GetAddressDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADDRESS_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                addressData: [],
            }
            break;
        case GET_ADDRESS_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                addressData: action.payload.availableAddress
            }
            break;
        case GET_ADDRESS_DATA_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                addressData: [],
                errorAddressMessage: action?.payload?.error || action?.error?.error
            }
            break;

        default:
            return {
                ...state
            }
            break;
    }
}