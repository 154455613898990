import {
    GET_ITEM_REQUEST,
    GET_ITEM_REQUEST_SUCCESS, GET_ITEM_REQUEST_FAILURE
} from "../actions/itemData.action";


const initialState = {
    itemData: null,
    isLoading: false,
    errorMessage: null
}

export const ItemReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case GET_ITEM_REQUEST:
            console.log(action)
            return {
                ...state,
                isLoading: true,
                itemData: null,
            }
            break;
        case GET_ITEM_REQUEST_SUCCESS:
            console.log(action.payload)
            return {
                ...state,
                isLoading: false,
            itemData: {...action.payload}
            }
            break;
        case GET_ITEM_REQUEST_FAILURE:
            console.log(action)
            return {
                ...state,
                isLoading: false,
                itemData: [],
                errorMessage: action.error.error
            }
            break;

        default:
            return {
                ...state
            }
            break;
    }
}