import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getVerifiedRequest } from "../redux/actions/verifyToken.action";

function mapStateToProps(state) {
  const { isLoading, successMessage, errorUserMessage } = state.headerModuleReducer.GetVerifyReducer;
  const { isLoading: userdataLoading, userData, errorUserMessage: userDataErrorMsg } = state.userProfileModuleReducer.GetUserDataReducer;

  return {
    isLoading, successMessage, errorUserMessage, userdataLoading, userData, userDataErrorMsg
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getVerifiedRequet: getVerifiedRequest,
    },
    dispatch
  );
}

class Header extends Component {

  static propTypes = {
    getVerifiedRequet: PropTypes.func,
    isLoading: PropTypes.bool,
    successMessage: PropTypes.string,
    errorUserMessage: PropTypes.string,
    userdataLoading: PropTypes.bool,
    userData: PropTypes.oneOfType([
      PropTypes.object, // Object type
      PropTypes.array,  // Array type
    ]),
    userDataErrorMsg: PropTypes.string
  }

  componentDidMount = () => {
    //this.props.getVerifiedRequet()
  }
  render() {
    console.log(this.props.successMessage);
    const { userData } = this.props;
    const localUser = JSON.parse(localStorage.getItem("user"));
    const init = localUser?.firstName?.charAt(0).toUpperCase() + localUser?.lastName?.charAt(0).toUpperCase();
    const listOfNavOption = [{
      heading: 'Home',
      urlPath: '/'
    }, {
      heading: 'Base 64',
      children: [{
        name: "Base 64 to PDF",
        path: '/base64topdf'
      }, {
        name: "Base 64 to Image",
        path: "/base64toimage"
      }, {
        name: "Any file to Base 64",
        path: "/filetobase64"
      }],
      //urlPath: 'base64topdf'
    }, {
      heading: 'Date & Time',
      children: [{
        name: "Unix Timestamp",
        path: "/unixconverter"
      }, {
        name: "Days Calculator",
        path: "/dayscalculator"
      }, {
        name: "Age Calculator",
        path: "/agecalculator"
      }]
    }, {
      heading: 'About Us',
      urlPath: '/aboutus'
    }
    ];
    return <>
      <header className='bg-light fixed-top' style={{ boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)" }} >
        <nav className="navbar container navbar-expand-lg navbar-light ">
        <a href="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
              <img src="asset/logo/utilitys-logo.png" className='logo' alt="Logo" ></img>
            </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
           
            <ul className="navbar-nav ml-auto">
              {listOfNavOption.map((item, i) => {
                return <>

                  {item.children ?
                    <li className="nav-item dropdown">
                      <NavLink className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {item.heading}
                      </NavLink>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        {item.children.map((itemC, index) => (<NavLink className="dropdown-item" to={itemC.path}>{itemC.name}</NavLink>))}
                      </div> </li>
                    : <li className="nav-item"><NavLink key={item} to={item.urlPath} className="nav-link int-header  int-header-nav-text int-header ">
                      {item.heading}
                    </NavLink ></li>}
                </>
              })}
            </ul>
          </div>
        </nav>
      </header>
    </>
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(Header);