export const GET_VERIFIED_REQUEST ='GET_VERIFIED_REQUEST';
export const GET_VERIFIED_REQUEST_SUCCESS ='GET_VERIFIED_REQUEST_SUCCESS';
export const GET_VERIFIED_REQUEST_FAILURE ='GET_VERIFIED_REQUEST_FAILURE';

export const getVerifiedRequest = payload => ({
    
    type: GET_VERIFIED_REQUEST, payload
}); 

export const getVerifiedRequestSuccess = (payload) => ({
    type: GET_VERIFIED_REQUEST_SUCCESS, payload
}); 

export const getVerifiedRequestFailure = (error) => ({
    type: GET_VERIFIED_REQUEST_FAILURE , error
}); 