import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class RestroTable extends Component {
    render() {
        return (
            <div>
                <h1>Tables</h1>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(RestroTable);