export const SET_ADDRESS_DATA_REQUEST ='SET_ADDRESS_DATA_REQUEST';
export const SET_ADDRESS_DATA_REQUEST_SUCCESS ='SET_ADDRESS_DATA_REQUEST_SUCCESS';
export const SET_ADDRESS_DATA_REQUEST_FAILURE ='SET_ADDRESS_DATA_REQUEST_FAILURE';

export const setAddressDataRequest = (payload) => ({
    type: SET_ADDRESS_DATA_REQUEST, payload
}); 

export const setAddressDataRequestSuccess = (payload) => ({
    type: SET_ADDRESS_DATA_REQUEST_SUCCESS, payload
}); 

export const setAddressDataRequestFailure = (error) => ({
    type: SET_ADDRESS_DATA_REQUEST_FAILURE , error
}); 