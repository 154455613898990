import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from "prop-types";
import { RenderedField, renderSelect } from './RenderField';

class GenericFields extends Component {
    static propTypes ={
        name: PropTypes.string,
        label: PropTypes.string,
        className:PropTypes.string,
        autoComplete:PropTypes.string,
        validate: PropTypes.arrayOf(PropTypes.func),
        options: PropTypes.array,
        type: PropTypes.string,
        isDisabled: PropTypes.bool
    }

    static defaultProps = {
        type: 'text',
        options:[
            { value: 'india', label: 'India' },
            { value: 'usa', label: 'USA' }
        ],
        isDisabled: false
    }

    render() {
        return (
            <div>
                {this.props.type === 'select' ?<Field {...this.props} component={renderSelect} disabled={this.props.isDisabled}/> : <Field {...this.props} component={RenderedField} /> }
                
            </div>
        );
    }
}

export default GenericFields;
