import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class AboutUs extends Component {
  render() {
    return (
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1 className="text-center mb-4">Welcome to Utility Tools</h1>

            <p>
              At Utility Tools, we offer a suite of efficient and convenient conversion tools designed to enhance productivity and streamline your workflow.
            </p>

            <div className="mb-4">
              <h3 className="text-primary">Our Services</h3>
              <ul>
                <li>
                  <strong><NavLink to={"/base64topdf"}>Base64 to PDF Converter:</NavLink></strong> Easily convert base64 encoded data into PDF documents. Our tool supports various data types, ensuring seamless conversion with just a few clicks.
                </li>
                <li>
                  <strong><NavLink to={"/unixconverter"}>Unix Time Converter:</NavLink></strong> Simplify Unix timestamp conversions to human-readable date and time formats, and vice versa. Our converter eliminates manual calculations, providing accurate results instantly.
                </li>
                <li>
                  <strong><NavLink to={"/dayscalculator"}>Days Calculator:</NavLink></strong> Calculate the difference between two dates in days, months, or years. Useful for planning projects, events, and more.
                </li>
                <li>
                  <strong><NavLink to={"/agecalculator"}>Age Calculator:</NavLink></strong> Determine age based on the birthdate provided. Perfect for age verification and date-related calculations.
                </li>
                <li>
                  <strong><NavLink to={"/base64toimage"}>Base64 to Image Converter:</NavLink></strong> Convert base64 encoded image data into viewable images. Ideal for handling image data in web applications.
                </li>
                <li>
                  <strong><NavLink to={"/filetobase64"}>Any File to Base64 Converter:</NavLink></strong> Transform any file into its Base64 representation. Useful for encoding files for transmission or storage.
                </li>
              </ul>
            </div>

            <div className="mb-4">
              <h3 className="text-primary">Why Choose Utility Tools?</h3>
              <ul>
                <li>
                  <strong>User-Friendly Interface:</strong> Our tools are designed for simplicity, ensuring a smooth experience for all users.
                </li>
                <li>
                  <strong>Fast and Accurate:</strong> Leveraging efficient algorithms, we deliver quick and precise conversion results, saving you time and effort.
                </li>
                <li>
                  <strong>Accessibility:</strong> Access our tools online anytime, anywhere, without additional software requirements.
                </li>
                <li>
                  <strong>Secure and Reliable:</strong> Your data privacy and security are paramount to us. Conduct conversions securely and confidentially.
                </li>
              </ul>
            </div>

            <p>
              Experience the convenience and efficiency of Utility Tools for all your conversion needs. Try our tools today and unlock a world of possibilities!
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
