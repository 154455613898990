import {
    GET_USER_DATA_REQUEST,
    GET_USER_DATA_REQUEST_SUCCESS, GET_USER_DATA_REQUEST_FAILURE
} from "../actions/getUserData.action";


const initialState = {
    userData: [],
    isLoading: false,
    errorUserMessage:null
}

export const GetUserDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_DATA_REQUEST:
            return {
                ...state,                
    errorUserMessage:null,
                isLoading: true,
                userData: [],
            }
            break;
        case GET_USER_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: true,
                userData: action.payload.customerDetails
            }
            break;
        case GET_USER_DATA_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                userData: [],
                errorUserMessage: action?.payload?.error || action?.error?.error
            }
            break;

        default:
            return {
                ...state
            }
            break;
    }
}