import {
    GET_REGISTER_REQUEST,
    GET_REGISTER_REQUEST_SUCCESS, GET_REGISTER_REQUEST_FAILURE
} from "../actions/getRegisterData.action";


const initialState = {
    registerUserData: [],
    isLoading: false,
    errorMessage: null
}

export const GetRegisterReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REGISTER_REQUEST:
            return {
                ...state,
                isLoading: true,
                registerUserData: [],
                errorMessage: null
            }
            break;
        case GET_REGISTER_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                registerUserData: {...action.payload}
            }
            break;
        case GET_REGISTER_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                registerUserData: [],
                errorMessage: action.error.error
            }
            break;

        default:
            return {
                ...state
            }
            break;
    }
}