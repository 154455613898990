export const GET_ITEM_REQUEST ='GET_ITEM_REQUEST';
export const GET_ITEM_REQUEST_SUCCESS ='GET_ITEM_REQUEST_SUCCESS';
export const GET_ITEM_REQUEST_FAILURE ='GET_ITEM_REQUEST_FAILURE';

export const getItemRequest = (payload) => ({
    
    type: GET_ITEM_REQUEST,payload
}); 

export const getItemRequestSuccess = (payload) => ({
    type: GET_ITEM_REQUEST_SUCCESS, payload
}); 

export const getItemRequestFailure = (error) => ({
    type: GET_ITEM_REQUEST_FAILURE , error
}); 