import { call, put, takeLatest } from "redux-saga/effects";
import { GET_USER_DATA_REQUEST, getUserDataRequestFailure, getUserDataRequestSuccess } from "../actions/getUserData.action";
import { BASE_API_URLS, BASE_METHODS } from "../../../Utils/enum";
import { createRequestOptions } from "../../../Utils/createOptions";
import { fetchData } from "../../../Utils/axiosApiCall";
import { getAuthToken } from "../../../Utils/authUser";

export function* getUserDataRequestSaga(payload) {
    try {
        const { email } = payload.payload;
        const userLogindata = JSON.parse(localStorage.getItem("user"));
        const apiUrl = BASE_API_URLS.CUSTOMER_DETAILS_API_URL;
        const bodyContent = {
            "email": email ? email : userLogindata.emailId ,
        };
        const methodValue = BASE_METHODS.POST
       
        const headersValue = { 'Content-Type': 'application/json' , "x-access-token" : userLogindata.token }

        const reqOptions = createRequestOptions(apiUrl, methodValue, headersValue, bodyContent)

        const {data} = yield call(fetchData, reqOptions);
        if (data.success) {
            yield put(getUserDataRequestSuccess(data));
        } else {
            yield put(getUserDataRequestFailure(data));
        }
    } catch (error) {
        yield put(getUserDataRequestFailure(error));
    }
}

export default function* userDataSagas() {
    yield takeLatest(GET_USER_DATA_REQUEST, getUserDataRequestSaga)
}