import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DaysCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      totalDays: null,
      totalMonths: null,
      totalYears: null,
      error: '',
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      totalDays: null,
      totalMonths: null,
      totalYears: null,
      error: '',
    });
  };

  calculateDuration = () => {
    const { startDate, endDate } = this.state;

    if (!startDate || !endDate) {
      this.setState({ error: 'Please enter both start and end dates.' });
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      this.setState({ error: 'Invalid date format. Please use YYYY-MM-DD.' });
      return;
    }

    const timeDiff = Math.abs(end - start);
    const totalDays = Math.floor(timeDiff / (1000 * 3600 * 24));
    const totalMonths = this.calculateTotalMonths(start, end);
    const totalYears = Math.floor(totalMonths / 12);

    this.setState({
      totalDays,
      totalMonths,
      totalYears,
    });
  };

  calculateTotalMonths = (start, end) => {
    const startYear = start.getFullYear();
    const startMonth = start.getMonth();
    const endYear = end.getFullYear();
    const endMonth = end.getMonth();

    return (endYear - startYear) * 12 + (endMonth - startMonth);
  };

  render() {
    const { startDate, endDate, totalDays, totalMonths, totalYears, error } = this.state;

    return (
      <div>
        <Helmet>
          <title>{"Utility's - Days Calculator"}</title>
          <meta name="description" content="Calculate duration between two dates with Utility's Days Calculator." />
          <meta name="keywords" content="Utility's, Days Calculator, Date Calculator, Duration Calculator" />
        </Helmet>
        <main className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="text-center py-3">
                <h1 className="h2">Days Calculator</h1>
                <p className="lead mb-1">Calculate duration between two dates</p>
              </div>
              <form>
                <div className="form-group">
                  <label htmlFor="startDate"><strong>Start Date</strong></label>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="endDate"><strong>End Date</strong></label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    onChange={this.handleInputChange}
                  />
                </div>
                <button className="btn btn-primary mb-4" type="button" onClick={this.calculateDuration}>
                  Calculate Duration
                </button>
                {error && <p className="text-danger">{error}</p>}
                {totalDays !== null && (
                  <div className="text-center">
                    <h2 className="mb-4">Duration</h2>
                    <div className="duration-details">
                      <p>
                        <strong>Total Years:</strong> {totalYears}
                      </p>
                      <p>
                        <strong>Total Months:</strong> {totalMonths}
                      </p>
                      <p>
                        <strong>Total Days:</strong> {totalDays}
                      </p>
                    </div>
                  </div>
                )}
              </form>
              <div className="mt-5">
                <h3>How to Use:</h3>
                <p>
                  Enter the start and end dates using the date input fields (`startDate` and `endDate`).
                </p>
                <p>
                  Click the "Calculate Duration" button to compute the total duration in years, months, and days.
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default DaysCalculator;
