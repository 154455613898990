import React from "react";
import reactDom, { render } from "react-dom";
import App from "./src/App"
import { Provider } from "react-redux";
import store from "./src/redux/store";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import client from "./src/graphql/client";

reactDom.render(
        <ApolloProvider client={client}>
                <Provider store={store}>
                        <App />
                </Provider>
        </ApolloProvider>,
        document.getElementById("root")
);